// @flow
import * as React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import EventLogo from './EventLogo'

function AlternativeLogo (props) {
    return (
        <div className={cn(styles.container, props.className)} style={props.style}>
            <span className={styles.title} style={{ color: props.theme.titleColor }}>{props.title}</span>
            <EventLogo />
        </div>
    )
}

export default withTheme(themes)(AlternativeLogo)
