// @flow
import * as React from 'react'

import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import cn from 'classnames'

import RegistrationForm from 'components/RegistrationForm'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import { Store } from 'Store'

import MainLogo from 'components/MainLogo'
import AlterntiveLogo from 'components/AlternativeLogo'

import type { Theme } from './themes/__supportedThemes'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme } = props
    const { state } = React.useContext(Store)

    const bgOverlay = null
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <div className={cn({
                    [styles.logoContainer]: true,
                    [styles.mainLogo]: true,
                    [styles.primaryLogoOnly]: theme.primaryLogoOnly
                })}>
                     <MainLogo/>
                </div>
                <div className={cn({
                    [styles.logoContainer]: true,
                    [styles.logoAlternative]: true,
                    [styles.primaryLogoOnly]: theme.primaryLogoOnly
                })}>
                    <AlterntiveLogo title={state.campaign.client.name} />
                </div>
            </div>

            <div className={styles.registerForm} style={{ background: bgOverlay }}>
                <div className={styles.formContainer}>

                    <div className="register-text-masked-layers js-register-text-masked-layers">
                        <h3 className="register-text-layer js-masked-layer">
                            <Trans data-mask="right" i18nKey="title">
                                <strong style={theme.registerText}>Spring into a new vehicle and Save!</strong>
                            </Trans>
                        </h3>
                    </div>

                    <RegistrationForm />
                </div>
            </div>
            <div className={styles.bgWrapper} style={{
                backgroundImage: isIE11 && `url(${theme.background})`,
                '--hero-bg': `url(${theme.background})`,
                '--hero-bg-mobile': `url(${theme.backgroundMobile})`
            }}/>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
