// @flow
import * as React from 'react'

import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function RegisterVoucherValue (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher

    return (
        <div className={styles.container} style={{
            '--background-color': theme.bgColor,
            '--background-tablet-color': theme.backgroundTabletColor,
            'backgroundImage': `url(${theme.background})`
        }}>
            <div className={styles.inner}>
                <span className={styles.title} style={{ '--title-color': theme.titleColor, '--title-tablet-color': theme.titleTabletColor }}>{t('title', {
                    province: state.campaign.client.address.province,
                    voucher: state.campaign.incentives.voucher.name,
                    type: state.campaign.incentives.voucher.type
                })}</span>
                <span className={styles.value} style={{ '--value-color': theme.valueColor, '--value-tablet-color': theme.valueTabletColor }}>{currencyFormatter.format(voucher.value)}</span>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterVoucherValue))
