// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import Voucher from 'components/Voucher'
import AlternativeLogo from 'components/AlternativeLogo'
import MainLogo from 'components/MainLogo'

import { Store } from 'Store'
import useInterval from 'hook/useInterval'
import { svgToImage } from '@shift-marketing/event-websites-helper'
import { sendAutoresponder, sendAppointmentDate, sendClientMessage } from 'Actions'
import { AppointmentWidgetWrapper } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import useTimeout from 'hook/useTimeout'
import { Currency } from 'i18n/NumberFormatter'
import cn from 'classnames'

function HeaderWithVoucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)

    const voucherRef = React.useRef(null)
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)

    useInterval(async () => {
        if (voucherRef.current && state.autoresponderShouldBeSent) {
            await svgToImage(voucherRef.current, async blob => {
                await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
            })
        }
    }, state.autoresponderShouldBeSent ? 1000 : null)

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidgetWrapper
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={theme.colors}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <>
            {appointmentWidget}
            <div className={styles.bgWrapper} style={{
                backgroundImage: isIE11 && `url(${theme.background})`,
                '--hero-bg': `url(${theme.background})`,
                '--hero-bg-mobile': `url(${theme.background})`
            }}>
                <div className={styles.container}>
                    <div className={cn({
                        [styles.logoContainer]: true,
                        [styles.primaryLogo]: theme.primaryLogo
                    })}>
                        {theme.primaryLogo ? <MainLogo /> : <AlternativeLogo title={state.campaign.client.name}/>}
                    </div>
                </div>

                <div className={styles.voucher}>
                    <Voucher primaryLogo={theme.primaryLogo} width='100%' ref={voucherRef} />
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(HeaderWithVoucher)
