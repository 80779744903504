// @flow
import * as React from 'react'

import cn from 'classnames'

import Logo from 'components/MainLogo'
import AlternativeLogo from '../AlternativeLogo'
import { Store } from 'Store'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

function Header (props) {
    const { state } = React.useContext(Store)
    const { theme } = props
    const name = state.campaign.client.name
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (<>
        {theme.primaryLogo
            ? <div className={cn(styles.bgWrapper, styles.altBgWrapper)} style={{
                backgroundImage: isIE11 && `url(${theme.cover})`,
                '--cover-bg': `url(${theme.cover})`,
                '--cover-bg-mobile': `url(${theme.coverMobile})`
            }}>
                <div className={styles.altLogoContainer}><Logo/></div>
                <div className={styles.carsAlternative} style={{ backgroundImage: `url(${theme.cars})` }} />
            </div>
            : <div>
                <div className={styles.bgWrapper} style={{
                    backgroundImage: isIE11 && `url(${theme.cover})`,
                    '--cover-bg': `url(${theme.cover})`,
                    '--cover-bg-mobile': `url(${theme.coverMobile})`
                }}>
                    <div className={styles.cars} style={{
                        backgroundImage: isIE11 && `url(${theme.background})`,
                        '--hero-bg': `url(${theme.background})`,
                        '--hero-bg-mobile': `url(${theme.backgroundMobile})`
                    }}>
                        <div className={styles.logoContainer}><AlternativeLogo title={name} /> </div>
                    </div>
                </div>
            </div>
        }
    </>)
}

export default withTheme(supportedThemes)(Header)
