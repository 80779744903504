// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

const LandingCover = ({ children, theme }) => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <div className={styles.landingContainer}>
            <span className={styles.container} style={{
                backgroundImage: isIE11 && `url(${theme.background})`,
                '--register-cover': `url(${theme.background})`,
                '--register-cover-tablet': `url(${theme.backgroundTablet})`,
                '--register-cover-mobile': `url(${theme.backgroundMobile})`
            }}>
            </span>
            <span className={styles.mask} style={{ backgroundImage: `url(${theme.mask})` }}/>
            <div className={styles.children}>
                {children}
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(LandingCover)
